body {
  margin: 0;
  font-family: var(--body), sans-serif;
  font-size: 14px;
  background: var(--background);
}

html, body, body > * {
  height: 100%;
}

* {
  box-sizing: border-box;
}
